<template>
  <b-container class="mt-5 mb-3">
    <h1>Kalendár akcií P-MATu</h1>
    <p>
      Na tejto stránke nájdeš prehľad všetkých akcií organizovaných P-MATom,
      ktoré by ťa mohli zaujímať.
    </p>
    <p>
      Ak ťa zaujímajú termíny spriatelených organizácií, alebo si ich chceš
      naimportovať priamo do kalendára, môžeš navštíviť stránku
      <a target="_blank" href="https://kockatykalendar.sk">kockatykalendar.sk</a
      >. Spoločne s ďalšími organizáciami na nej pravidelne zverejňujeme
      aktuálne termíny.
    </p>
    <hr />
    <LoadingSpinner :is-loaded="loaded">
      <h2>Najbližšie akcie</h2>
      <b-row>
        <CalendarEvent
          v-for="(event, i) of futureEvents"
          :key="i"
          :event="event"
        />
      </b-row>
      <h2>Minulé akcie</h2>
      <b-row>
        <CalendarEvent
          v-for="(event, i) of pastEvents"
          :key="i"
          :event="event"
        />
      </b-row>
    </LoadingSpinner>
  </b-container>
</template>

<script>
import { LoadingSpinner } from "frontend-common";
import axios from "axios";
import CalendarEvent from "@/components/CalendarEvent";

export default {
  name: "Calendar",
  components: {
    CalendarEvent,
    LoadingSpinner,
  },
  data() {
    return {
      futureEvents: [],
      pastEvents: [],
      loaded: false,
    };
  },
  mounted() {
    // TODO: Remove this when https://github.com/kockatykalendar/data/issues/76 is resolved.
    const month = new Date().getMonth() + 1;
    const startYear = new Date().getFullYear() + (month <= 9 ? -1 : 0);

    axios.get("https://data.kockatykalendar.sk/index.json").then((res) => {
      let calendarFile = null;
      res.data.forEach((e) => {
        if (e.start_year === startYear) {
          calendarFile = e.filename;
        }
      });

      if (calendarFile !== null) {
        this.loadCalendar(calendarFile);
      } else {
        this.$root.dangerToast("Nepodarilo sa načítať udalosti.");
      }
    });
  },
  methods: {
    loadCalendar(calendarFile) {
      axios
        .get(`https://data.kockatykalendar.sk/${calendarFile}`)
        .then(({ data }) => {
          const events = data
            .filter((event) => event.organizers.indexOf("p-mat") !== -1)
            .sort((a, b) => new Date(a.date.start) - new Date(b.date.start));
          const now = new Date();
          this.futureEvents = events.filter(
            (event) => new Date(event.date.end || event.date.start) >= now,
          );
          this.pastEvents = events
            .filter(
              (event) => new Date(event.date.end || event.date.start) < now,
            )
            .reverse();
        })
        .finally(() => (this.loaded = true));
    },
  },
};
</script>
